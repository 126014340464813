<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              v-if="hasPermission('create honours and awards')"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({ name: 'researcher.honours-awards.create', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="awards"
              :sort-mapping="sortMapping"
              :title="'honours & awards'"
              :total="awardsTotal"
              :fetch-path="'awards/fetch'"
              :filter-path="'awards/filter'"
              :export-path="'awards/export'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'researcher.dashboard', params: { id: field.user.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="showInfoSheet(field)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View honour & award
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('update honours and awards')"
                        :href="$router.resolve({name: 'researcher.honours-awards.edit', params: { id: field.id }, query: { actAs: $route.query.actAs }}).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('delete honours and awards')"
                        @click="showDeleteModal(field.id)"
                      >
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="hasAdminPermission('view honours and awards')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Honour & award</label>
                  <input
                    v-model="filters.title"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Honour & Award: ${$event.target.value}`, value: `${$event.target.value}` }, 'title')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From year</label>
                  <date-picker
                    v-model="filters['start_year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `From: ${$event}`, value: `${$event}` }, 'start_year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To year</label>
                  <date-picker
                    v-model="filters['end_year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `To: ${$event}`, value: `${$event}` }, 'end_year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Description</label>
                  <input
                    v-model="filters.description"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Description: ${$event.target.value}`, value: `${$event.target.value}` }, 'description')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Memoir</label>
                  <select
                    v-model="filters.memoir"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Memoir: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'memoir')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Publish in web</label>
                  <select
                    v-model="filters.published_web"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Publish in web: ' + ($event.target.value === 'Publish in web' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'published_web')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="Publish in web"
                    >
                      Yes
                    </option>
                    <option
                      value="Don't publish in web"
                    >
                      No
                    </option>
                  </select>
                </div>
              </template>
              <template #info-sheet-item="{item}">
                <div class="offcanvas-body offcanvas-body--view">
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Researcher<br>
                      <strong>{{ item.user.name }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Honour / Award<br>
                      <strong>{{ item.title }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Year<br>
                      <strong>{{ item.year }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Publish in web<br>
                      <strong
                        class="badge"
                        :class="item.published_web ? 'badge-light-success' : 'badge-light-danger'"
                      >{{ item.published_web ? 'Yes' : 'No' }}</strong></p>
                  </div>

                </div>
                <div
                  v-if="hasPermission('update honours and awards')"
                  class="offcanvas-footer mt-auto"
                >
                  <a
                    title="edit this honour & award"
                    class="btn btn-primary mb-1 d-grid w-100"
                    :href="$router.resolve({ name: 'researcher.honours-awards.edit', params: { id: item.id }, query: { actAs: $route.query.actAs } }).href"
                    target="_blank"
                  >Edit</a>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    DatePicker,
  },
  data() {
    return {
      sortedField: 'Year',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Year',
          checked: true,
          order: 2,
        },
        {
          name: 'Honour/Award',
          checked: true,
          order: 3,
        },
        {
          name: 'Description',
          checked: true,
          order: 4,
        },
        {
          name: 'Memoir',
          checked: true,
          order: 5,
        },
        {
          name: 'Publish in web',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Researcher: 'user.name',
        Year: 'year',
        'Honour/Award': 'title',
        Description: 'description',
        Memoir: 'memoir',
        'Publish in web': 'published_web',
      },
      title: 'Honours & Awards',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      awards: 'awards/awards',
      awardsTotal: 'awards/awardsTotal',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'awards')
    await this.$store.dispatch('awards/filter', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'awards',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'awards', url: `${Vue.prototype.$groupUrl}/honours-awards/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
